
@import "./mixins";


.#{$prefix}modal {
  @include modal;
  
}
@include modal-responsive;

