@mixin notification {
  @include notification-tokens;
  @include notification-base;
  @include notification-header;
  @include notification-body;
}

@mixin notification-tokens {
  --notification-background: var(--color-secondary-01);
  --notification-shadow: 0 3px 6px rgba(var(--rgb-secondary-09), 0.16);
  --notification-padding: var(--spacing-scale-2x) var(--spacing-scale-4x);
  --notification-width: 50vw;
  --notification-height: calc(100vh - 86px);
  --notification-tab: var(--font-size-scale-down-01);
}

@mixin notification-base {
  background: var(--notification-background);
  box-shadow: var(--notification-shadow);
  max-height: var(--notification-height);
  max-width: var(--notification-width);
  overflow: auto;
  z-index: 1;
}

@mixin notification-header {
  .notification-header {
    border-bottom: 1px solid $color-secondary-04;
    padding: var(--notification-padding);

    *:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin notification-body {
  .notification-body {
    .#{$prefix}tab {
      .tab-item {
        padding: 0;
      }

      .tab-content {
        overflow-y: auto;
        .#{$prefix}item {
          --interactive-rgb: var(--rgb-secondary-08);
          --item-color: var(--text-color);
          padding: var(--notification-padding);
          position: relative;
          white-space: normal;

          .status {
            left: var(--spacing-scale-base);
            position: absolute;
            top: var(--spacing-scale-3x);
          }

          span {
            display: block;
          }
        }
      }
    }
  }
}

.#{$prefix}notification {
  @include notification;
}

@media (max-width: $grid-breakpoint-sm) {
  .#{$prefix}notification {
    --notification-width: 100vw;
  }
}
