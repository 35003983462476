@mixin tabela {
  @include tabela-tokens;
  @include tabela-base;
  @include tabela-top-bar;
  @include tabela-responsive;
  @include tabela-search;
  @include tabela-selection-bar;
  @include tabela-selection;
  @include tabela-footer;
  @include tabela-density;
}

@mixin tabela-tokens {
  --table-border: 1px solid var(--color-secondary-04);
  --table-color: var(--text-color);
  --table-padding: var(--spacing-scale-3x);
  --table-shadow: var(--surface-shadow-sm);
  --table-font-weight: var(--font-weight-medium);
  --table-header-background: var(--color-secondary-01);
  --table-header-font-weight: var(--font-weight-semi-bold);
  --table-row-size: var(--table-row-medium);
  --table-row-small: var(--spacing-scale-base);
  --table-row-medium: var(--spacing-scale-2x);
  --table-row-large: var(--spacing-scale-3x);
}

@mixin tabela-base {
  // Mobile
  @include tabela-card;

  .dropdown {
    [data-toggle="dropdown"] {
      + * {
        left: auto;
        right: 0;
      }
    }
  }

  tr[hidden],
  td[hidden] {
    display: none;
  }
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    @include tabela-desktop;
  }
}

@mixin tabela-card {
  caption {
    opacity: 0;
    position: absolute;
    z-index: calc(var(--z-index-layer-0) - 1);
  }

  tr {
    &:hover {
      background-image: linear-gradient(rgba(var(--rgb-secondary-08), var(--hover)),
      rgba(var(--rgb-secondary-08), var(--hover)));
    }
  }

  tbody {
    .is-selected td,
    .is-selected tr,
    .is-selected td::before {
      background-color: v(color-primary-lighten-01);
      color: v(color-secondary-01);
    }

    .is-selected td {
      input + label::before {
        --focus-color: var(--color-support-10);
      }
    }
  }
}

@mixin tabela-desktop {
  table {
    --table-border: 1px solid var(--color-secondary-04);
    --table-padding: var(--spacing-scale-3x);
    --table-shadow: var(--surface-shadow-none);
  }

  td {
    &::before {
      content: none;
    }
  }

  th,
  td {
    display: table-cell;
    padding: var(--table-row-size) calc(var(--table-padding) * 0.5);

    &:first-child {
      padding-left: var(--table-padding);
    }

    &:last-child {
      padding-right: var(--table-padding);
    }
  }

  tr {
    display: table-row;
    margin: 0;
  }

  thead {
    display: table-header-group;
  }

  tbody {
    display: table-row-group;
  }
}

@mixin tabela-headers {
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    .headers,
    .clone-headers {
      background: var(--table-header-background);
      display: block;
      overflow-y: hidden;
      position: sticky;
      top: 0;
      z-index: var(--z-index-layer-0);

      .scroller {
        align-items: center;
        display: flex;
        overflow-x: hidden;
      }

      .item {
        font-weight: var(--table-header-font-weight);
        padding: var(--table-row-size) calc(var(--table-padding) * 0.5);

        &:first-child {
          padding-left: var(--table-padding);
        }

        &:last-child {
          padding-right: var(--table-padding);
        }

        .br-checkbox {
          margin-bottom: 0;
        }
      }
    }

    .syncscroll {
      overflow: auto;
    }
  }
}

@mixin tabela-top-bar {
  // Mobile
  .top-bar {
    --table-padding: var(--spacing-scale-base) var(--spacing-scale-2x);
    align-items: center;
    background-color: var(--color-secondary-01);
    display: flex;
    padding: var(--table-padding);

    .title,
    .table-title {
      flex-grow: 1;
      font-size: var(--font-size-scale-up-02);
      font-weight: 600;
    }

    .actions-trigger {
      margin-right: var(--spacing-scale-base);
    }
  }
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    .top-bar {
      background-color: v(color-secondary-01, $color-secondary-01);

      title {
        font-size: v(font-size-scale-up-03, $font-size-scale-up-03);
        line-height: v(font-line-height-medium,
        $font-line-height-medium);
      }
    }
  }
}

@mixin tabela-responsive {
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    .responsive {
      overflow: auto;
    }
  }
}

@mixin tabela-search {
  .table-header {
    &.show {
      .top-bar {
      
        display: none;
      }
    }
  }
  .search-bar {
    --table-padding: var(--spacing-scale-base) var(--spacing-scale-2x);
    align-items: center;
    background-color: var(--table-header-background);
    display: none;
    left: 0;
    padding: var(--table-padding);
    position: initial;
    right: 0;
    top: 0;
    z-index: var(--z-index-layer-0);

    &.show {
      display: flex;
    }
    .#{$prefix}input {
      flex: 1;

      label {
        display: none;
      }

      input {
        + .#{$prefix}button {
          --button-size: var(--button-small);
          position: absolute;
          right: var(--spacing-scale-half);
        }
      }
      + .#{$prefix}button {
        margin-left: var(--table-row-medium);
      }
    }
  }
  @media (min-width: $grid-breakpoint-sm) {
    .search-bar {
      --table-header-background: var(--bg-color);
    }
  }
}

@mixin tabela-selection-bar {
  // Mobile
  .selected-bar,
  .selection-bar {
    --table-padding: var(--spacing-scale-base) var(--spacing-scale-2x);
    align-items: center;
    background-color: var(--interactive);
    bottom: 0;
    display: none;
    left: 0;
    padding: var(--table-padding);
    right: 0;
    top: 0;

    &.show {
      display: flex;
    }

    .info {
      align-items: center;
      display: flex;
      flex-grow: 1;
      white-space: nowrap;

      [data-toggle="check-all"],
      .select-all {
        font-size: v(font-size-scale-up-03, $font-size-scale-up-03);
        margin-right: v(spacing-scale-half);
        padding: 0;
      }
    }

    .text,
    .count {
      color: v(color-secondary-01, $color-secondary-01);
      line-height: v(font-line-height-low, $font-line-height-low);
      padding-right: v(spacing-scale-half);
    }

    &.is-active,
    &.active {
      opacity: 1;
      top: 0;
    }
  }
}

@mixin tabela-selection {
  --checkbox-size: 24px;
  // Tablet+
  @media (min-width: $grid-breakpoint-sm) {
    &[data-selection] {
      tbody {
        tr {
          position: static;
        }

        td {
          padding-left: calc(var(--table-padding) * 0.5);

          &:first-child {
            left: var(--table-padding);
            padding-left: var(--table-padding);
            padding-right: calc(var(--table-padding) * 0.5);
            position: static;
            width: var(--checkbox-size);
          }
        }
      }
    }
  }
}

@mixin tabela-footer {
  // Mobile
  .footer,
  .table-footer {
    background: var(--bg-color);
    padding: var(--spacing-scale-2x);
    .#{$prefix}input {
      .#{$prefix}button {
        transform: rotate(180deg);
      }
    }
    .#{$prefix}list {
      bottom: 100%;
    }
  }
}

@mixin tabela-density {
  @each $size in small, medium, large {
    &.#{$size} {
      table,
      .headers,
      .clone-headers .item {
        --table-row-size: var(--table-row-#{$size});
      }
    }
  }
}

.#{$prefix}table {
  @include tabela;
}
