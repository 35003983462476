@mixin button {
  @include button-tokens;
  @include button-base;
  @include button-block;
  @include button-circle;
  @include button-sizes;
  @include button-emphasis;
  @include button-colors;
  @include button-states;
}

@mixin button-tokens {
  --button-background: transparent;
  --button-border: transparent;
  --button-color: var(--interactive);
  --button-radius: 100em;
  --button-font-size: var(--font-size-scale-up-01);
  --button-font-weight: var(--font-weight-semi-bold);
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  --button-padding: 0 var(--spacing-scale-3x);
  --button-img: 20px;
}

@mixin button-base {
  align-items: center;
  background-color: var(--button-background);
  border: 1px solid var(--button-border);
  border-radius: var(--button-radius);
  color: var(--button-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  height: var(--button-size);
  justify-content: center;
  padding: var(--button-padding);
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;

  img {
    max-height: var(--button-img);
  }
}

@mixin button-block {
  &.block {
    width: 100%;
  }
  @each $breakpoint in "sm", "md", "lg", "xl" {
    @include media-breakpoint-up(#{$breakpoint}) {
      &.block-#{$breakpoint} {
        width: 100%;
      }
      &.auto-#{$breakpoint} {
        width: auto;
      }
    }
  }
}

@mixin button-circle {
  &.circle,
  &[circle],
  &.is-circle {
    border-radius: 50%;
    padding: 0;
    width: var(--button-size);
  }
}

@mixin button-sizes {
  @each $size in "xsmall", "small", "medium", "large" {
    &.#{$size},
    &[#{$size}],
    &.is-#{$size} {
      --button-size: var(--button-#{$size});
    }
  }
}

@mixin button-emphasis {
  &.primary,
  &[primary],
  &.is-primary {
    --interactive-rgb: var(--color-dark-rgb);
    --button-background: var(--interactive);
    --button-color: var(--color-dark);

    &.inverted,
    &.is-inverted,
    &[inverted] {
      @include dark-mode;
      --hover: var(--surface-opacity-xs);
      --interactive-rgb: var(--color-dark-rgb);
      --button-color: var(--background-dark);
    }
  }

  &.secondary,
  &[secondary],
  &.is-secondary {
    --button-border: var(--interactive);

    &.inverted,
    &.is-inverted,
    &[inverted] {
      @include dark-mode;
    }
  }

  &.inverted,
  &.is-inverted,
  &[inverted] {
    @include dark-mode;
  }
}

@mixin button-colors {
  @each $color in "danger", "success", "warning", "info" {
    &.#{$color},
    &[#{$color}],
    &.is-#{$color} {
      --button-background: var(--#{$color});
      @if $color != "warning" {
        --button-color: var(--background);
      }
    }
  }
}

@mixin button-states {
  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled) {
    @include focus;
    @include hover;
  }

  &.active,
  &.is-active,
  &[active] {
    --button-background: var(--active);
    --button-color: var(--background);
  }

  &.loading,
  &[loading],
  &.is-loading {
    &::after {
      border-color: var(--button-color) var(--button-color) transparent;
      border-style: solid;
    }

    &.primary,
    &.danger,
    &.success,
    &.info {
      &::after {
        border-color: var(--button-color) var(--button-color) transparent;
      }
    }
  }
}
