@mixin signin{
  @include signin-base;
  @include signin-emphasis;
}

@mixin signin-base{
  z-index: var(--z-index-layer-0);
  --button-background: var(--interactive);
  --button-color: var(--background-alternative);
  // --interactive-rgb: var(--color-dark-rgb);
}
// @include button;
@mixin signin-emphasis {
  &.primary,
  &[primary],
  &.is-primary {
    // --interactive-rgb: var(--color-dark-rgb);
    --button-color: var(--background-alternative);
    
    &.inverted,
    &.is-inverted,
    &[inverted] {
      @include dark-mode;
      --hover: var(--surface-opacity-xs);
      --interactive-rgb: var(--color-dark-rgb);
      --button-color: var(--background-dark);
    }
  }

  &.secondary,
  &[secondary],
  &.is-secondary {
    --button-background: var(--background-alternative);
    --button-color: var(--interactive);
    --button-border: none;

    &.inverted,
    &.is-inverted,
    &[inverted] {
      @include dark-mode;
      --button-background: var(--background-dark);
      --button-color: var(--interactive-dark);
    }
  }
  &.inverted,
  &.is-inverted,
  &[inverted] {
    @include dark-mode;
  }

}
