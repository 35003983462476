// Criar as colunas da Grid
.col,
[class*="col-"] {
  padding-left: calc(var(--grid-gutter) * 0.5);
  padding-right: calc(var(--grid-gutter) * 0.5);
  width: 100%;
}

@include columns($grid-desktop-columns);

// Criar os containers da Grid
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  --grid-margin: var(--grid-portrait-margin);
  --grid-maxwidth: var(--grid-portrait-maxwidth);
  margin-left: auto;
  margin-right: auto;
  max-width: var(--grid-maxwidth);
  padding-left: var(--grid-margin);
  padding-right: var(--grid-margin);
  width: 100%;
}

// Criar as linhas da Grid
.row {
  --grid-gutter: var(--grid-portrait-gutter);
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--grid-gutter) * -0.5);
  margin-right: calc(var(--grid-gutter) * -0.5);
}

// Criar containers e colunas dos breakpoints
@include grid-breakpoint($grid-breakpoint-sm, "sm", "tablet");
@include grid-breakpoint($grid-breakpoint-md, "md", "tablet");
@include grid-breakpoint($grid-breakpoint-lg, "lg", "desktop");
@include grid-breakpoint($grid-breakpoint-xl, "xl", "tv");
