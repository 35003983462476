@import "../../partial/scss/base";
.#{$prefix}wizard {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  .wizard-progress {
    background-color: v(color-secondary-02, $color-secondary-02);
    display: grid;
    grid-template-columns: repeat(auto-fit,
    minmax(0, 1fr)); //Padrao horizontal
    grid-template-rows: none;
    min-height: 164px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: v(spacing-scale-7x);
    width: 100%;

    .wizard-progress-btn {
      background-color: transparent;
      border: none;
      box-shadow: none !important;
      color: var(--interactive);
      font-size: v(font-size-scale-up-01, $font-size-scale-up-01);
      line-height: 19px;
      max-height: 90px;
      padding-bottom: 0;
      padding-top: v(spacing-scale-2x);
      position: relative;
      text-indent: 0;
      transition-delay: 0s;
      transition-duration: 0.15s;
      transition-property: all;
      transition-timing-function: linear;

      &[disabled] {
        color: rgba(var(--interactive-rgb), var(--disabled));
        opacity: 1;

        &::before {
          opacity: var(--disabled);
        }
      }

      &:focus {
        outline: none;
      }

      &.focus-visible:not([disabled]):not(.disabled)::before,
      &:focus-visible:not([disabled]):not(.disabled)::before {
        outline-color: var(--focus-color);
        outline-offset: var(--focus-offset);
        outline-style: var(--focus-style);
        outline-width: var(--focus-width);
      }

      &:hover:not([disabled])::before {
        background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)),
        rgba(var(--interactive-rgb), var(--hover)));
      }

      &:active:not([disabled])::before {
        background-image: linear-gradient(rgba(var(--interactive-rgb), var(--pressed)),
        rgba(var(--interactive-rgb), var(--pressed)));
      }

      &::before {
        background-color: v(color-secondary-01, $color-secondary-01);
        border: 2px solid var(--interactive);
        border-radius: 50%;
        box-sizing: border-box;
        color: var(--interactive);
        content: attr(step) !important;
        display: block;
        font-size: v(font-size-scale-up-02, $font-size-scale-up-02);
        font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
        height: 36px;
        left: 50%;
        line-height: 29px;
        margin-top: -13px;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        transition: all 0.15s linear 0s,
          -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s,
          transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s,
          transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s,
          -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        width: 36px;
        z-index: 3;
      }

      &::after {
        background-color: v(color-primary-lighten-01,
        $color-primary-lighten-01) !important;
        content: "";
        display: block;
        height: 1px;
        left: calc(-50% + 34px * 0.5);
        position: absolute;
        top: 5px;
        transition-delay: 0s;
        transition-duration: 0.15s;
        transition-property: all;
        transition-timing-function: linear;
        width: calc(100% - 34px);
        z-index: 1;
      }

      &:first-child::after {
        display: none;
      }

      &[active] {
        color: var(--active);
        font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
      }

      &[active]::after {
        background-color: var(--active);
        height: 1px;
      }

      &[active]::before {
        background-color: var(--active);
        border: 2px solid var(--color-secondary-01);
        color: v(color-secondary-01, $color-secondary-01);
        font-weight: v(font-weight-bold, $font-weight-bold);
      }
    }
  }

  .wizard-form {
    height: calc(100% - 164px);
    position: relative;

    .wizard-panel {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      left: 1%;
      margin-bottom: v(spacing-scale-4x);
      opacity: 0;
      position: absolute;
      top: 0;
      visibility: hidden;
      width: 100%;

      &[active] {
        height: 100%;
        left: 0 !important;
        opacity: 1;
        transition-delay: 0s;
        transition-duration: 0.5s;
        transition-property: all;
        transition-timing-function: linear;
        visibility: visible;
      }

      .wizard-panel-content {
        border-top: 1px solid v(color-secondary-04, $color-secondary-04);
        flex-grow: 1;
        height: auto;
        max-height: calc(100% - 74px);
        overflow-x: hidden;
        overflow-y: auto;
        padding: var(--spacing-scale-base) var(--spacing-scale-2x);

        > :last-child {
          margin-bottom: 220px;
        }
      }

      .wizard-panel-btn {
        align-self: flex-end;
        background-color: v(color-secondary-02, $color-secondary-02);
        border-top: 1px solid v(color-secondary-04, $color-secondary-04);
        height: fit-content;
        margin-top: 1px;
        padding: v(spacing-scale-2x) 1.5%;
        width: 100%;
      }

      .wizard-btn-next,
      .wizard-btn,
      .wizard-btn-prev {
        float: right;
        margin-left: v(spacing-scale-2x);
      }

      .wizard-btn-canc {
        float: left;
      }
    }
  }

  &[vertical] {
    .wizard-progress {
      float: left;
      grid-template-columns: 1fr; //Padrão vertical
      height: 100%;
      max-width: 260px;
      overflow-x: hidden;
      padding-top: 0;
      position: relative;
      text-align: right;

      .wizard-progress-btn {
        height: 100%;
        line-height: 100%;
        max-height: 100%;
        padding-bottom: 0;
        padding-right: 70px;
        padding-top: 0;
        text-align: right;

        &::before {
          left: calc(100% - 32px);
          line-height: 26px;
          margin-top: calc(#{v(spacing-scale-2x)} * -1);
          position: absolute;
          text-align: center;
          top: 50%;
        }

        &::after {
          height: calc(100% - 34px);
          left: calc(100% - 33px);
          position: absolute;
          top: calc(-50% + 36px * 0.5);
          width: 1px;
        }

        &[active]::after {
          width: 1px;
        }
      }
    }

    &[scroll] {
      .wizard-progress {
        overflow-x: hidden;
        overflow-y: auto;

        .wizard-progress-btn {
          min-height: 100px;
        }
      }
    }

    .wizard-form {
      float: left;
      height: 100%;
      width: calc(100% - 260px);

      .wizard-panel {
        .wizard-panel-content {
          border-top: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .wizard-progress {
      max-height: 100px;
      min-height: 100px;
      overflow-y: hidden;
      padding-top: v(spacing-scale-3x);
      position: relative;
      transition-delay: 0s;
      transition-duration: 0.25s;
      transition-property: all;
      transition-timing-function: linear;

      .wizard-progress-btn {
        .info {
          font-size: v(font-size-scale-down-02,
          $font-size-scale-down-02);
          line-height: 1em;
          text-align: center;
        }
      }
      // Ajuste Grip Lines
      &::after {
        background-color: transparent; // v(color-secondary-04, $color-secondary-04);
        bottom: 2px;
        color: var(--interactive);
        content: "\f7a4";
        display: block;
        font-family: "Font Awesome 5 Free", sans-serif;
        font-size: var(--switch-icon-size);
        font-weight: var(--font-weight-black);
        height: 1em;
        left: 50%;
        margin-left: -0.5em;
        position: absolute;
        top: unset;
        transition-delay: 0s;
        transition-duration: 0.25s;
        transition-property: all;
        transition-timing-function: linear;
        width: auto;
      }
    }

    .wizard-form {
      height: calc(100% - 100px);

      .wizard-panel {
        border-top: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        max-height: 100%;

        .wizard-panel-content {
          height: auto;
          max-height: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          padding: var(--spacing-scale-half) var(--spacing-scale-base);
          transition-delay: 0s;
          transition-duration: 0.25s;
          transition-property: all;
          transition-timing-function: linear;

          &::-webkit-scrollbar {
            width: var(--spacing-scale-half);
          }

          &::-webkit-scrollbar-track {
            background: var(--color-secondary-02);
            border-radius: var(--spacing-scale-half);
          }

          &::-webkit-scrollbar-thumb {
            background: var(--color-secondary-04);
            border-radius: var(--spacing-scale-half);
          }
        }

        .wizard-panel-btn {
          align-items: flex-end;
          background-color: var(--bg-color);
          display: flex;
          flex-wrap: wrap;
          height: fit-content;
          justify-content: center;
          //margin-left: -2%;
          padding: 0 !important;
          position: absolute;
          width: calc(100% - var(--spacing-scale-half));

          .br-button {
            float: none;
            margin: v(spacing-scale-base) 5%;
            width: 90%;
          }

          .wizard-btn-next,
          .wizard-btn {
            order: 1;
          }
        }
      }
    }

    &[collapsed] {
      .wizard-progress {
        max-height: 64px;
        min-height: 64px;
        overflow-y: hidden;
        padding-bottom: v(spacing-scale-3x);
        padding-top: v(spacing-scale-3x);

        &::after {
          margin-top: v(spacing-scale-4xh);
        }

        .wizard-progress-btn {
          padding-bottom: v(spacing-scale-base);

          .info {
            display: none;
          }
        }
      }

      .wizard-form {
        height: calc(100% - 64px);

        .wizard-panel {
          .wizard-panel-content {
            overflow-x: hidden;
            overflow-y: auto;
          }
        }
      }
    }

    &[vertical] {
      .wizard-progress {
        max-height: 100%;
        max-width: 110px;

        &::after {
          height: 1em;
          left: unset;
          margin-top: -0.5em;
          right: 2px;
          top: 50%;
          transform: rotate(-90deg);
          width: 1em;
        }

        .wizard-progress-btn {
          line-height: 18px;
          padding-right: v(spacing-scale-7x);

          .info {
            line-height: 1.6em;
            margin-top: -0.5em;
            position: absolute;
            right: 54px;
          }
        }
      }

      .wizard-form {
        height: 100%;
        max-width: calc(100% - 110px);
        min-width: calc(100% - 110px);
        transition-delay: 0s;
        transition-duration: 0.25s;
        transition-property: all;
        transition-timing-function: linear;

        .wizard-panel {
          height: 100%;
          max-height: 100%;
        }
      }

      &[collapsed] {
        .wizard-progress {
          max-width: 60px;
          padding-bottom: 0;
          padding-top: 0;

          .wizard-progress-btn {
            padding-right: 0;

            .info {
              display: none;
            }
          }
        }

        .wizard-form {
          max-width: calc(100% - 60px);
          min-width: calc(100% - 60px);
        }
      }
    }
  }
}

.wizard-sample-1 {
  height: 400px;
}

.wizard-sample-2 {
  height: 400px;
}
