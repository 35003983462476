@import "../../partial/scss/base";
@import "mixins";



.#{$prefix}button {
  @include button;
  

  &.sign-in {
    @include signin;
    
  }
}
