@import "../../partial/scss/base";
$diameter: 84px;
$position: calc(50% - (84px * 0.5));
$diameter-md: 44px;
$position-md: calc(50% - (44px * 0.5));
$diameter-sm: 24px;
$position-sm: calc(50% - (24px * 0.5));
$rotationSteps: 100;
$rotationIncrement: 1.8;
$stroke: 5px;

.rotulo {
  color: v(color-secondary-08);
  font-size: v(font-size-scale-base);
  font-weight: v(font-weight-medium);
}

* {
  &.is-loading,
  &.loading,
  &[loading] {
    color: transparent !important;
    min-height: 44px;
    pointer-events: none;
    position: relative;
    z-index: var(--z-index-layer-4);

    &::after {
      animation: spinAround 1.3s infinite linear;
      border: 2px solid var(--interactive);
      border-radius: 50%;
      border-right-color: transparent;
      content: "";
      display: block;
      height: $diameter-sm;
      left: $position-sm;
      position: absolute;
      top: $position-sm;
      width: $diameter-sm;
    }

    &.primary {
      &::after {
        border-bottom-color: v(color-secondary-01);
        border-left-color: v(color-secondary-01);
        border-top-color: v(color-secondary-01);
      }
    }

    &.medium,
    &[md] {
      &::after {
        border-width: 4px;
        height: $diameter-md;
        left: $position-md;
        top: $position-md;
        width: $diameter-md;
      }
    }
  }
}

.#{$prefix}loading {
  background-color: v(color-secondary-04);
  border-radius: 50%;
  height: $diameter;
  width: $diameter;
  z-index: var(--z-index-layer-4);

  &-mask,
  &-fill {
    backface-visibility: hidden;
    border-radius: 50%;
    clip: rect(0, $diameter, $diameter, $diameter * 0.5);
    height: $diameter;
    overflow: hidden;
    position: absolute;
    width: $diameter;
  }

  &-fill {
    background: var(--interactive);
    clip: rect(0, $diameter * 0.5, $diameter, 0);
  }

  &::after {
    $diameter: $diameter - $stroke * 2;
    background: v(color-secondary-01);
    border-radius: 50%;
    color: var(--interactive);
    content: attr(data-progress) "%";
    font-size: v(font-size-scale-up-02);
    font-weight: v(font-weight-semi-bold);
    height: $diameter;
    line-height: $diameter;
    margin: $stroke;
    position: absolute;
    text-align: center;
    width: $diameter;
  }
  @keyframes fill {
    0% {
      transform: rotate(0deg);
    }
    @for $step from 1 through $rotationSteps {
      $rotation: ($step/100) * 360;
    }
  }
  @for $step from 1 through $rotationSteps {
    $rotation: ($step/100) * 360;
    &[data-progress="#{$step}"] {
      $rotate: $step * $rotationIncrement;
      .#{$prefix}loading-mask.full,
      .#{$prefix}loading-fill {
        animation: fill ease-in-out 2s;
        transform: rotate(#{$rotate}deg);
      }
    }
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
