select {
    background: #ffffff;
    border: 1px solid #888;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 4px;
    height: 40px;
    padding-left: var(--spacing-scale-2x);
    padding-right: var(--spacing-scale-2x);
    font-size: var(--font-size-scale-base);
    width: 100% !important;
}

option span {
    color: #ccc;
}

.cinza {
  color: #ededed !important;
}

th:not([align]) {
  color: #333 !important;
  background-color: #ededed;
  border: none !important;
}

.ui-sidebar-mask {
  display: none !important;
}
